





















































































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"
import popWindow from "../../components/popWindow.vue"
import { Swiper as SwiperClass, Pagination, Autoplay, Navigation, EffectFade } from "swiper/core"
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter"
import "swiper/swiper-bundle.min.css"
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
SwiperClass.use([Pagination, Autoplay, Navigation, EffectFade])

@Component({
	components: {
		topNav,
		Swiper,
		SwiperSlide,
		popWindow
	}
})
export default class Default extends Vue {
	swiperList: { img: any; title: string; info: string }[] = [
		{
			img: require("../../assets/img/InvitingUsers/socialSecurity.png"),
			title: "社保服务",
			info: "提供40个城市44个网点城市社保与公积金缴纳与补缴服务，累计服务超31万用户。"
		},
		{
			img: require("../../assets/img/InvitingUsers/healthExamination.png"),
			title: "健康体检",
			info:
				"支持150城450门店体检套餐浏览和下单购买，预约并体检完成后，可在1-7个工作日查看电子体检报告。"
		},
		{
			img: require("../../assets/img/InvitingUsers/psychologicalTests.png"),
			title: "心理测评",
			info:
				"注重个人心理健康，提供7大类、合计200多套专业心理测评题目，随时随地测评和查看报告。"
		},
		// {
		// 	img: require("../../assets/img/InvitingUsers/commercialInsurance.png"),
		// 	title: "商业保险",
		// 	info: "提供丰富的商业保险种类，主要覆盖医疗险和意外险。社保＋商保，保障更全面。"
		// }
	]
	swiperOptionInviter: any = {
		centeredSlides: true,
		slidesPerView: "auto",
		spaceBetween: 10,
		loop: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true
		}
	}
	nickname: string = ""
	headimg: string = ""
	phone: string = ""
	disabled: boolean = false
	clickOne: boolean = false
	replaceReferee: number = 0
	popWindow: boolean = false
	txt: string = ""
	no: string = ""
	yes: string = ""

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {
		console.log(this.$route.query)
		if (this.$route.query.nickname) {
			this.nickname = decodeURIComponent((this.$route as any).query.nickname)
		}
		if (this.$route.query.headimg) {
			this.headimg = (this.$route as any).query.headimg
		}
	}
	mounted() {
		window.document.title = '新人专享'
	}

	blurPhone() {
		var RegCellPhone = new RegExp(/^1\d{10}$/)
		if (!RegCellPhone.test(this.phone)) {
			let data: any = ["请输入正确的手机号", true]
			this.VueFunc.$emit("balloons", data)
			setTimeout(() => {
				data = ["请输入正确的手机号", false]
				this.VueFunc.$emit("balloons", data)
			}, 2000)
		}
	}

	acceptInviter() {
		if (this.clickOne == false) {
			var RegCellPhone = new RegExp(/^1\d{10}$/)
			if (this.phone == "" || !RegCellPhone.test(this.phone)) {
				let data: any = ["请输入正确的手机号", true]
				this.VueFunc.$emit("balloons", data)
				setTimeout(() => {
					data = ["请输入正确的手机号", false]
					this.VueFunc.$emit("balloons", data)
				}, 2000)
			} else {
				this.clickOne = true
				let data = {
					refereeUserId: this.$route.query.userid,
					refereeMobile: this.$route.query.phone,
					refereeAccount: this.$route.query.nickname,
					beRecommendMobile: this.phone,
					replaceReferee: this.replaceReferee
				}
				// console.log(data)
				this.$api.BindRecommend(data).then((res: any) => {
					this.clickOne = false
					console.log(res)
					if (res.code == 200) {
						let data: any = ["绑定成功", true]
						this.VueFunc.$emit("balloons", data)
						setTimeout(() => {
							data = ["绑定成功", false]
							this.VueFunc.$emit("balloons", data)
						}, 2000)
						this.disabled = true
					} else if (res.msg == "您已经接受了其他用户的邀请，是否变更为当前邀请人？") {
						this.txt = "您已经接受了其他用户的邀请，是否变更为当前邀请人？"
						this.no = "否"
						this.yes = "是"
						this.popWindow = true
					} else if (res.msg == "输入的手机号已注册！") {
						this.txt = "您已经注册过啦，即将打开/下载'易社保'app"
						this.no = "取消"
						this.yes = "允许"
						this.popWindow = true
					}
					setTimeout(()=>{
						this.openOrDownload()
					},5000)
				})
			}
		}
	}

	getChildNo(val: boolean) {
		this.popWindow = val
	}

	getChildYes(val: boolean) {
		this.popWindow = val
		this.replaceReferee = 1
		if (this.txt == "您已经接受了其他用户的邀请，是否变更为当前邀请人？") {
			this.acceptInviter()
		} else if (this.txt == "您已经注册过啦，即将打开/下载'易社保'app") {
			this.openOrDownload()
		}
	}

	openOrDownload() {
		const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		console.log(isAndroid,isiOS)
		if (isAndroid) {
			window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.fair.esb"
			this.openApp("fairhr://eshebao.app/openwith")
		} else if (isiOS) {
			window.location.href = "https://apps.apple.com/cn/app/id1483906919"
		}
	}

	openApp(src: any) {
		let ifr = document.createElement("iframe")
		ifr.src = src
		ifr.style.display = "none"
		document.body.appendChild(ifr)
		window.setTimeout(function() {
			document.body.removeChild(ifr)
		}, 2000)
	}
}
