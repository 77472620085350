












import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class Default extends Vue {
	@Prop(String) txt!: string
	@Prop(String) no!: string
	@Prop(String) yes!: string

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {}

	mounted() {}
	toFatherNo() {
		this.$emit("getChildNo", false)
	}
	toFatherYes() {
		this.$emit("getChildYes",false)
	}
}
